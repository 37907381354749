export default {
  MfaEnterPasscodeForm__EXPIRATION_NOTICE:
    "It may take a minute to receive your code. The code will expire after 15 minutes.",
  MfaEnterPasscodeForm__FIELD_ERROR_ACCOUNT_LOCKED:
    "Maximum number of tries reached. Please try again later.",
  MfaEnterPasscodeForm__FIELD_ERROR_OTP_EXPIRED: "Your passcode has expired, please try again.",
  MfaEnterPasscodeForm__FIELD_ERROR_OTP_INVALID: "Incorrect passcode, please try again.",
  MfaEnterPasscodeForm__HEADING: "Authenticate your account",
  MfaEnterPasscodeForm__INVALID_PASSCODE: "Incorrect code, please try again.",
  MfaEnterPasscodeForm__PASSCODE: "Passcode",
  MfaEnterPasscodeForm__PROMPT_EMAIL:
    "Please enter the verification code sent to your email address at",
  MfaEnterPasscodeForm__PROMPT_SMS:
    "Please enter the verification code sent to your phone number at",
  MfaEnterPasscodeForm__RESEND_LINK_ENABLED_ARIA_LIVE: "Resend passcode link is now enabled.",
  MfaEnterPasscodeForm__RESEND_PASSCODE: "Resend a new code",
  MfaEnterPasscodeForm__RESEND_PROMPT: "Haven’t received it?",
  MfaEnterPasscodeForm__RESEND_SUCCESS_EMAIL:
    "An email with verification code has been sent to your email address",
  MfaEnterPasscodeForm__RESEND_SUCCESS_SMS:
    "A text message with verification code has been sent to your phone",
  MfaEnterPasscodeForm__RESEND_TIMER_ARIA: (data) =>
    `Resend passcode link will be enabled in ${data.seconds} seconds.`,
  MfaEnterPasscodeForm__RESTART: "Choose other verification",
  MfaEnterPasscodeForm__SUBMIT: "Verify identity",
};
