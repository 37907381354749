export default {
  MfaEnterPasscodeForm__EXPIRATION_NOTICE:
    "It may take a minute to receive your code. The code will expire after 15 minutes.",
  MfaEnterPasscodeForm__HEADING: "Authenticate your account",
  MfaEnterPasscodeForm__INVALID_PASSCODE: "Incorrect code, please try again.",
  MfaEnterPasscodeForm__PASSCODE: "Passcode",
  MfaEnterPasscodeForm__PROMPT_EMAIL:
    "Please enter the verification code sent to your email address at",
  MfaEnterPasscodeForm__PROMPT_SMS:
    "Please enter the verification code sent to your phone number at",
  MfaEnterPasscodeForm__RESEND_PASSCODE: "Resend passcode",
  MfaEnterPasscodeForm__RESEND_PROMPT: "Haven’t received it?",
  MfaEnterPasscodeForm__RESEND_SUCCESS_EMAIL:
    "An email with verification code has been sent to your email address",
  MfaEnterPasscodeForm__RESEND_SUCCESS_SMS:
    "A text message with verification code has been sent to your phone",
  MfaEnterPasscodeForm__SUBMIT: "Verify identity",
};
